    <template>
  <div>
    <div class="col-12">
      <div class="card">
        <div class="flex justify-content-between flex-wrap">
          <div>
            <h1>
              ORDEN DE PRODUCCIÓN
              <span class="" style="color: #0b3b5a">{{ order.id }}</span>
            </h1>
          </div>
          <div class=""></div>
        </div>

        <div class="">
          <div class="formgrid grid">
            <div class="field col">
              <label for="detail">CLIENTE</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.cliente.name }}
              </span>
            </div>

            <div class="field col">
              <label for="detail">O/C</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.oc }}
              </span>
            </div>

            <div class="field col">
              <label for="detail">FECHA INGRESO</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ formatDate(order.datein) }}
              </span>
            </div>

            <div class="field col">
              <label for="detail">ENTREGA PROMETIDA</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ formatDate(order.dateout) }}
              </span>
            </div>
          </div>
        </div>

        <div class="">
          <div class="formgrid grid">
            <div class="field col">
              <label for="detail">CANTIDAD</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.quantity }}
              </span>
            </div>

            <div class="field col">
              <label for="detail">MONEDA</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ currencyName (order.currency) }}
              </span>
            </div>
            <div class="field col">
              <label for="detail">TOTAL</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ formatCurrency(order.cdolarpeso) }}
              </span>
            </div>

            <div class="field col">
              <label for="detail">OBSERVACIONES</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.obs }}
              </span>
            </div>
          </div>
        </div>

		<hr />


		<div class="flex justify-content-between flex-wrap">
			<div>
				<h3>ORDENES DE TRABAJO</h3>
			</div>
			<div class="">

				<Button class="p-button-text" @click="this.$router.push('/produccion/' + this.$route.params.id +'/addorder')">+ AGREGAR ORDEN DE TRABAJO</Button>

			</div>


		</div>

        <DataTable
          ref="dt"
          :value="orders"          
          dataKey="id"          
          :rows="10"
          :filters="filters"          
          responsiveLayout="scroll"
		  v-model:selection="selectedOti" selectionMode="single"
        >
 

          <Column
            field="number"
            header="N°"
            :sortable="true"
            headerStyle="min-width:5rem;"
          >
            <template #body="slotProps">
              <div class="flex">
                <div class="p-1">
                  <span>{{ slotProps.data.id }}</span>
                </div>
              </div>
            </template>
        </Column>

        <Column
            field="piece"
            header="PIEZA"
            :sortable="true"
            headerStyle="min-width:10rem;"
          >
          </Column>
        <Column
            field="quantity"
            header="CANTIDAD"
            :sortable="true"
            headerStyle="min-width:3rem;"
          > 
          </Column>


  
            <Column
                field="status"
                header="ESTADO"
                :sortable="true"
                headerStyle="min-width:5rem;"
                >
                <template #body="slotProps">
                    <span class="p-column-title">Estado</span>
                    <div :class="'status_box status-' + slotProps.data.status">{{ statusName(slotProps.data.status, slotProps.data.current_operator) }}</div>
                </template>
            </Column>
			<Column
				field="current_operator"
				header="OPERARIO"
				:sortable="true"
				headerStyle="min-width:10rem;"
			>
            <template #body="slotProps">
              {{ giveMeName(slotProps.data.current_operator, 'usuario') }}
            </template>
          </Column>
		           <Column
            field="machine"
            header="MAQUINARÍA"
            :sortable="true"
            headerStyle="min-width:10rem;"
          >
            <template #body="slotProps">
              {{ giveMeName(slotProps.data.machine_id, 'maquinaria') }}
            </template>
          </Column>

         <Column
            field="time"
            header="DURACIÓN"
            :sortable="true"
            headerStyle="min-width:10rem;"
          >
            <template #body="slotProps">

              <span >
                <OtiClock :opGroup="1" :order="slotProps.data" :size="0" />
              </span>

            </template>
          </Column>
        </DataTable>

      </div>
    </div>
  </div>
</template>

<script>
import OtiClock from "./../../components/OtiClock.vue";
import CrudService from "./../../services/crud.service.js" 

export default {
  components: {
    OtiClock,
  },
  data() {
    return {
      order: [],
      orders: [],
      clients: [],
      users: [],
      machines: [],
	    selectedOti: null,
    };
  },
   

  mounted() {
      CrudService.getCRUD("cliente").then((data) => (this.clients = data));
      CrudService.getCRUD("usuario").then((data) => (this.users = data));
      CrudService.getCRUD("maquinaria").then((data) => (this.machines = data));
     
  },
  watch: {
	selectedOti: function(newValue) {
		if (newValue) {
			this.$router.push('/orden/' + newValue.id)
		}
	}
  },
  created() {
    this.getOP();
    this.getOrders();
    // get route name
    this.routeName = this.$route.name;
     
  },
  computed: { 

  },
  methods: {

	  formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    formatDate(value) {
      // format date to dd-mm-yyyy
      if (value) {
        let date = new Date(value);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return day.toString().padStart(2, '0') + '/' + month.toString().padStart(2, '0') + '/' + year;
      }      
    },

    statusName(value, operator=null){      
      switch (value) {
        case "0":
          if(operator){
            return " Sin Iniciar por " + this.giveMeName(operator, 'usuario');
          } else {
            return " Sin asignar";
          }
        case "1":
          return " En proceso";
        case "2":
          return " En pausa";
        case "3":
          return " En calidad";
        case "4":
          return " Aprobada";
        case "5":
          return " Desaprobada";
        case "6":
          return " En TORNO / EN COLA ";
        case "7":
          return " EN TORNO / EN PROCESO";
          
        default:
          return value;
      }
    },
    currencyName(value){

      switch (value) {
        case "1":
          return 'Dolar';
          break;
        case "0":
          return 'Peso';
          break;
        case "2":
          return 'Euro';
          break;
        default:
          return 'Otro';
          break;
        }
    },
    giveMeName(id, table) {

      if(table == 'cliente'){
        let exist = this.clients.find((client) => client.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        
        }

      }

      if(table == 'maquinaria'){
        let exist = this.machines.find((machine) => machine.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        
        }

      }

      if(table == 'usuario'){
        let exist = this.users.find((user) => user.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        
        }

      }

    


    },
    getOP() {
      //CrudService.getCRUD("operario").then((data) => (this.operators = data));
      CrudService.getCRUD("produccion", this.$route.params.id).then((data) => {
        this.order = data;
        this.orders = data.otis;
      });
    },
    getOrders() {
      //CrudService.getCRUD("orden", "ordenproduccion", this.$route.params.id).then((data) => (this.orders = data)); 
    },
  },
};
</script>

<style scoped>


.field > label {
  color: #9a9a9a;
  letter-spacing: 0.64px;
  font-size: 16px;
  text-transform: uppercase;
}

.field > span {
  display: block;
  text-align: left;
  letter-spacing: 0px;
  color: #0c0c0c;
  opacity: 1;
  border: none;
  background: transparent;
  font-size: 20px;
  line-height: 25px;
}

.plan_button {
  background: white;
  border: 1px solid #d8d8d8;
  font-size: 16px;
  line-height: 19px;
  color: #9a9a9a;
  width: 483px;
  height: 56px;
  text-align: left;
}

.plan_button:hover {
  border: 1px solid #d8d8d8;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 483px;
  height: 56px;
}
.status_box {

          display: inline-block;
          width: 95px;
          font-weight: bolder;
          text-align: center;
          padding: 5px;
          text-transform: uppercase;
        }
        .status-7 {
            border: 1px solid #44b73a71;
            background-color: #44b73a71;
        }      
        .status-6 {
            border: 1px solid #2ce4d49a;
            background-color: #2ce4d535;
            color: #000;
        }
        .status-5 {
            border: 1px solid #24406c;
            background-color: #4f8ff7;
            color: #fff;
        }
        .status-4 {
            border: 1px solid #2ce4d49a;
            background-color: #2ce4d535;
        }
        .status-3 {
            border: 1px solid #44b73a71;
            background-color: #44b73a71;
        }
        .status-2 {
            border: 1px solid #ff99005b;
            background-color: #ff99005b;
        }
        .status-1 {
            border: 1px solid #44b73ae3;
            background-color: #44b73a71;
          
        }
        .status-0 {
            border: 1px solid #d8d8d89a;
            background-color: #d8d8d89a;
        }

</style>